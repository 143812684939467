.mySelect:focus{
    -webkit-appearance: none;
    outline: none;
    border: none;
}
.select-container select:focus{
    outline: none;
    border: none;
}

select:focus{
    outline: none;
    border:none
}