body{
  font-family: 'Inter', sans-serif;
  font-size: 0.8rem;
}

.main-container {
  padding: 0 !important;
}

body, html {
  height: 100%;
}

#root {
  height: 100%;
}