.loader {
    height: 100vh;
    margin-top: 20% !important;
    margin-left: 45%;
}
.main-container {
    .dashboard-container {
        .card_container {
            .card {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                box-shadow: 0px 1px 2px rgba(31,41,55,0.08);
                border-radius: 8px;
                background-color: #FFFFFF;
                margin-bottom: 30px;
                padding: 17px 30px;
                border: unset;
                height: 120px;
            }
        }
    }
}
@media screen and (max-width: 760px) {
    .dash_heading {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 1px;
        margin-right: 1px;
    }
    .dash_title {
        margin-bottom: 1rem;
        margin-left: 1px;
        margin-right: 1px;
    }
    .dash_user {
        display: none;
    }
    .card_container {
        margin-left: 1px;
        margin-right: 1px;
    }
}
