@mixin placeholder-color() {
    &::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.36);
    }
    &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: rgba(0, 0, 0, 0.36);
    }
    &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: rgba(0, 0, 0, 0.36);
    }
    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: rgba(0, 0, 0, 0.36);
    }
}

body {
    font-family: Inter;
}

.main-container {
    &.login {
        justify-content: center;
        display: flex;
        flex-direction: column;
        height: inherit;
        overflow: hidden;
        background-color: #f1fafe;
        background: url("../../assets/login_bg.svg") no-repeat right #f1fafe;
        background-position: bottom;
        .container-fluid {
            background: #fff;
        }
        .logo-text {
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            color: #1A365D;
            letter-spacing: -0.02em;
            line-height: 44px;
        }
        .submit-button {
            background: #0000FF;
            border-radius: 6px;
            color: #fff;
            width: 100%;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
        }
    }
    .error {
        color: red;
    }
    .p-x-30 {
        padding-left: 30px;
        padding-right: 30px;
    }
    .p-x-32 {
        padding-left: 32px;
        padding-right: 32px;
    }
    .white-background {
        background-color: white;
    }

    .light-grey-background {
        background-color: #fbfafa;
    }

    .dash_user {
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    }

    .custom-date-range-picker {
        border: 1px solid #e2e8f0;
        box-sizing: border-box;
        border-radius: 6px;
        background-color: white;
        min-width: 275px;
        width: auto;

        .img-width {
            min-width: 16px !important;
        }

        input {
            width: 190px;
        }
    }

    .nav-bar {
        min-height: 70px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
        .dropdown-menu{
            min-width: 11rem; 
            .dropdown-item {
                padding: 0.25rem 0.5rem;
            }
        }
        
        .flag {
            width: 30px;
            &.main-flag {
                max-height: 23px !important;
                width: auto !important;
            }
        }
        &.nav-bar-dashboard {
            min-height: 80px;
            margin-bottom: 3px;
        }

        &.p-l-30 {
            padding-left: 30px;
        }
        &.p-r-50 {
            padding-right: 50px;
        }

        &.p-x-30 {
            padding-left: 30px;
            padding-right: 30px;
        }

        &.p-x-35 {
            padding-left: 35px;
            padding-right: 35px;
        }

        .input-group {
            height: 40px;
            width: 320px;

            .input-group-text {
                border: 1px solid #e2e8f0;
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
                border-right-style: none;
                padding: 0.2rem 0.5rem 0.2rem 1rem;
                color: #a0aec0;
                font-size: 20px;
            }

            #search-input {
                @include placeholder-color();
                border: 1px solid #e2e8f0;
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;
                border-left-style: none;
                padding-left: 4.5px;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;

                &:focus {
                    outline: 0 !important;
                    outline-style: none !important;
                    box-shadow: none !important;
                }
            }
        }
        .username-color {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #1e88e5;

            svg {
                font-size: 10px;
                margin-top: 4px;
            }
        }
    }

    .custom-empty-date-range-picker {
        border: 1px solid #e2e8f0;
        box-sizing: border-box;
        border-radius: 6px;
        background-color: white;
        max-width: 150px;
        width: auto;

        .img-width {
            min-width: 16px !important;
        }

        input {
            // width: 40px;
        }
    }

    .opacity-2 {
        opacity: 0.2;
    }

    .dashboard-container {
        padding-left: 38px;
        padding-right: 38px;
        .charts-container {
            flex-wrap: nowrap;
        }

        .mid-container-height {
            height: 330px;
        }

        .full-container-height {
            height: 450px;
        }

        .inter-chart-container {
            padding: 5px 20px 60px 20px;
            background-color: white;
            border-radius: 10px;

            .chart-title {
                font-style: normal;
                font-weight: bold;
                font-size: 20px;
                line-height: 32px;
                color: #374151;
            }

            .chart-menu {
                color: #9ca3af;
            }
        }

        .chart-shadow {
            box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
            border-radius: 8px !important;
        }

        .p-x-15 {
            padding-left: 15px;
            padding-right: 15px;
        }

        .p-r-15 {
            padding-right: 15px;
        }

        .p-l-15 {
            padding-left: 15px;
        }
    }

    /* remove the original arrow */
    #filter-div {
        .input-lg {
            -webkit-appearance: none;
            -moz-appearance: none;
            -o-appearance: none;
            background: url("../../assets/chevronDown.svg") no-repeat right #ddd;
            background-position-x: calc( 100% - 10px );  
            background-color: white;
        }
    }
}

@media screen and (max-width: 760px) {
    .header_container {
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .heading_title {
        margin-bottom: 1rem;
        align-items: center;
        justify-content: center;
    }
    .main-container {
        .input-group {
            width: auto !important;
            margin-right: 10px;
        }

        .dashboard-container {
            padding-left: 25px;
            padding-right: 25px;
            .charts-container {
                flex-wrap: wrap;
            }
        }
    }
}
