::selection {
    color: #fff;
    background: #11101d;
}
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 90px;
    background: #15397f;
    padding-top: 8px 15px;
    z-index: 99;
    transition: all 0.8s ease;
    .sidebar-li {
        a {
            width: 100%;
            height: 100%;
        }
        .tooltip {
            display: none;
        }
        .links_name {
            .red {
                color: red !important;
            }
        }
    }
    &:not(.active) {
        ul li {
            height: 64px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            a {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: #fafdff;

                &:hover {
                    text-decoration: none;
                }

                &:active {
                    text-decoration: none;
                }

                img {
                    width: 26px;
                }

                span {
                    padding-top: 10px;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 12px;
                    color: #fafdff;
                }
            }
        }

        .logo_content {
            .logo {
                color: #fff;
                display: flex;
                height: 50px;
                width: 100%;
                align-items: center;
                opacity: 0;
                pointer-events: none;
                transition: all 0.5s ease;
                opacity: 1;
                padding-top: 15px;

                .logo_name {
                    display: none;
                    opacity: 0;
                }

                img {
                    width: 40px;
                }
            }
        }

        .active-sidebar-link {
            background-color: #ffffff;

            a {
                color: #1e88e5 !important;
                text-decoration: none;

                &:hover {
                    text-decoration: none;
                }

                &:active {
                    text-decoration: none;
                }

                .sidebar-svg {
                    filter: invert(42%) sepia(94%) saturate(1757%) hue-rotate(188deg) brightness(93%) contrast(93%);
                }

                img {
                    width: 30px;
                }

                span {
                    color: #1e88e5 !important;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 12px;
                }
            }

            .icon {
                fill: #1e88e5 !important;
            }
        }
    }

    &.active {
        width: 200px;

        .logo_content {
            height: 105px;

            a {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-top: 17px;

                &:hover {
                    text-decoration: none;
                }

                &:active {
                    text-decoration: none;
                }

                .logo {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    img {
                        width: 30px;
                    }

                    .logo_name {
                        padding-left: 13px;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 27px;
                        color: #fafdff;
                    }
                }
            }
        }

        ul li {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            position: relative;
            list-style: none;
            line-height: 25px;
            text-align: center;
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
            padding: 0px 24px;
            height: 48px;

            a {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                color: #fafdff;

                img {
                    width: 20px;
                }

                span {
                    padding-left: 23px;
                    color: #fafdff;
                }
            }
        }

        .active-sidebar-link {
            a {
                text-decoration: none;

                &:hover {
                    text-decoration: none;
                }

                &:active {
                    text-decoration: none;
                }

                .sidebar-svg {
                    filter: invert(42%) sepia(94%) saturate(1757%) hue-rotate(188deg) brightness(93%) contrast(93%);
                }

                span {
                    color: #1e88e5;
                }
            }
        }

        .links_name {
            font-weight: bold !important;
            font-size: 14px !important;
            line-height: 17px !important;
        }

        .active-sidebar-link {
            padding-top: 8px;
            padding-bottom: 8px;
            background-color: #fafdff;

            a {
                color: #1e88e5 !important;
            }

            .icon {
                fill: #1e88e5 !important;
            }

            &.dashboard-active {
                margin-left: 8px;

                a {
                    margin-left: -8px;
                }
            }
        }
    }

    .logo_content {
        padding: 8px 15px;
        padding-bottom: 15px;

        &.bottom-right-radius {
            border-radius: 0 0 5px 0px;
        }
    }

    .top-right-radius {
        border-radius: 0 5px 0 0;
    }
}

.home_content {
    position: absolute;
    width: calc(100% - 90px);
    left: 90px;
    transition: all 0.5s ease;
}

.sidebar.active ~ .home_content {
    z-index: 100;
}

.sidebar.active ~ .home_content {
    width: calc(100% - 200px);
    left: 200px;
}

/***** Responsive Settings *****/

@media screen and (max-width: 760px) {
    .home_content {
        position: relative;
        width: 100%;
        left: 0px;
    }
    .sidebar.active ~ .home_content {
        width: 100%;
        left: 0;
        z-index: 0;
    }
    .sidebar {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 80px;
        width: 100%;
        background-color: #15397f;
        ul {
            display: none;
        }
        &.active {
            width: 100%;
            #btn {
                left: 0px;
            }
            ul {
                display: flex;
                flex-direction: column;
                background-color: #15397f;
                opacity: 1;
                width: 50px;
                transition: all 0.8s ease;
                margin-top: 0px;
                margin-left: 0px;
                left: 5px;
                border-radius: 5px;
                align-items: center;
                li {
                    &:hover{
                        .tooltip {
                            transition: all 0.5s ease;
                            opacity: 1;
                            top: 50%;
                        }
                    }
                    a {
                        .links_name {
                            display: none;
                        }
                    }
                    .tooltip {
                        position: absolute;
                        left: 125px;
                        top: 0;
                        transform: translate(-50%, -50%);
                        border-radius: 6px;
                        height: 35px;
                        width: 120px;
                        background: #fff;
                        line-height: 35px;
                        text-align: center;
                        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
                        transition: 0s;
                        opacity: 0;
                        pointer-events: none;
                        display: block;
                    }
                }
            }
        }
        .sidebar .logo_content {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
            .logo_name {
                opacity: 1;
            }
            #btn {
                position: relative;
                z-index: 99;
                top: 10px;
                left: 0;
                font-size: 30px;
                height: 80px;
                width: 70px;
                line-height: 50px;
                transform: translateX(-50%);
                color: #fff;
            }
            .user_logo {
                display: block;
            }
        }
    }
    
}
