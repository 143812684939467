.btn-export{
    display: flex;
    flex-direction:row;
    justify-content:center;
    align-items: center;
    padding:10px 12px 10px 12px;
    border: 1px solid #E2E8F0;
    border-radius:8px;
}
.btn-export:hover{
    outline: 0;
    background-color:#DEE5EE ;
}