.selectContainer select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: 0 !important;
    background: #FFF;
    background-image: none;
  }
  /* Remove IE arrow */
  select::-ms-expand {
    display: none;
  }
  /* Custom Select */
  .selectContainer .mySelect {
    position: relative;
    display: flex;
    width: 20em;
    height: 3em;
    line-height: 3;
    background: #FFF;
    overflow: hidden;
    border-radius: .25em;
  }
  select {
    flex: 1;
    padding: 0 .5em;
    color: #000;
    cursor: pointer;
  }
  /* Arrow */
  .mySelect::after {
    content: '\25BC';
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 1em;
    background: #FFF;
    cursor: pointer;
    pointer-events: none;
    -webkit-transition: .25s all ease;
    -o-transition: .25s all ease;
    transition: .25s all ease;
  }
  /* Transition */
  .mySelect:hover::after {
    color: #000;
  }

  .filter-container{
    font-family: 'Inter';
    display: flex;
    align-items: center;
    border-radius: 10px;
    margin-right: 10px;
    padding-left: 10px;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    line-height: 20px;
  }

.main-container {
  overflow-x: hidden;
  .btn-export {
    .export-text {
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      margin-left: 5px;
      color: #2D3748;
    }
  }
  .parcels, .consolidation {
    .heading_title {
      padding-left: 3px;
    }
  }
  .drop{
    .header_container {
      .heading_title {
        padding-left: 5px;
      }
      .btn-export {
        margin-right: 8px !important;
      }
    }
    .table-wrapper {
      margin-right: 2rem;
      margin-left: 2rem;
      .expanding-row {
        .expand-data {
          .expand-val {
            max-width: 283px !important;
            width: 283px !important;
          }
        }
      }
    }
  }
  .parcels {
    .table-wrapper {
      margin-right: 1.5rem;
      margin-left: 1.5rem;
    }
  }
  .pick-container {
    .header {
      .heading_title {
        padding-left: 5px;
      }
      .btn-export {
        margin-right: 9px !important;
      }
    }
    .table-wrapper {
      margin-right: 2.7rem;
      margin-left: 2.7rem;
    }
    .table-container {
      .search-width {
        padding-right: 37px !important;
      }
      .expanding-row {
        .expand-data {
          .expand-val {
            max-width: 283px !important;
          }
        }
      }
    }
    .nav-tabs {
      margin-left: 2.7rem;
      margin-right: 2.7rem;
    }
  }
  .dispatched, .suites-container {
    .header {
      .heading_title {
        padding-left: 6px;
      }
      .btn-export {
        margin-right: 7px !important;
      }
    }
    .table-wrapper {
      margin-right: 2rem;
      margin-left: 2rem;
    }
  }
  .users-container {
    .header {
      .heading_title {
        padding-left: 5px;
      }
      .btn-export {
        margin-right: 8px !important;
      }
    }
    .table-wrapper {
      margin-right: 2.4rem;
      margin-left: 2.4rem;
    }
  } 
  .promo-container {
    .header {
      .heading_title {
        padding-left: 5px;
      }
      .btn-export {
        margin-right: 8px !important;
      }
    }
    .table-wrapper {
      margin-right: 1.9rem;
      margin-left: 1.9rem;
    }
  }

  .consolidated-item {
    .header_container {
      .header {
        .heading_title {
          padding-left: 5px;
        }
        .btn-export {
          margin-right: 8px !important;
        }
      }
    }
    .table-responsive {
      overflow-x: hidden;
      .react-bootstrap-table, .react-bootstrap-table-pagination {
        margin-left: 38px !important;
        margin-right: 38px !important;
      }
      .expanding-row {
        .expand-data {
          .expand-val {
            max-width: 283px !important;
          }
        }
      }
    }
  }
  
    #menuBtn{
      font-size: 25px;
      color: #15397f;
    }

    hr{
      width: 98%;
      background-color: #E2E8F0;
      align-self: center;
    }
    .table-header-title{
        color: #1A365D;
        font-size: 34px;
        font-weight: 700;
        line-height: 41.15px;
    }
    .table-header-number-of-items{
        color: #2D3748;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
    }    
    
  .table-container {
    .table-data {
      background: #FBFAFA;
      .expand-cell {
        width: 20px;
        svg{
            color: #2D3748;
            height: 10px;
            width: 10px;
        }
      }
      .expanding-row {
        &.pick {
          .expand-data {
            padding-bottom: 8px !important;
            padding-top: 8px !important;
            border-bottom: 1px solid #E2E8F0;
            &:last-child { 
              border-bottom: unset;
            }
            .value {
              margin: auto 0px auto 0px;
            }
            .basic-expand-value {
              max-width: 310px;
            }
            .suites-expand {
              max-width: 200px;
            }
          }
        }
        &.suites {
          .expand-data {
            padding-bottom: 6px !important;
            padding-top: 6px !important;
            border-bottom: 1px solid #E2E8F0;
            &:last-child { 
              border-bottom: unset;
            }
            .value {
              margin: auto 0px auto 0px;
            }
            .basic-expand-value {
              max-width: 310px;
            }
            .suites-expand {
              max-width: 200px;
            }
          }
        }
      }
      .react-bootstrap-table-pagination {
        margin: 25px;
        border-radius: 6px;
        padding: 10px 10px 5px 10px;
        border: 1px solid #E2E8F0;
        
        .next-previous-icon {
          padding-left: 5px;
          padding-right: 5px;
        }
        .react-bs-table-sizePerPage-dropdown {
          &::before {
            content: "Show rows per page";
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: #2D3748;
            padding: 10px;
          }
          #pageDropDown {
            background-color: #fff;
            border: 1px solid #E2E8F0;
            border-radius: 6px;
            color: #000;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            &:focus {
              box-shadow: unset;
            }
            
          }
        }
        .pagination-per-page {
          display: block;
          width: 100%;
          height: 100%;
          text-decoration: none;
        }
        .next-previous-icon {
          color: #2D3748;
          font-size: 20px;
        }
        .react-bootstrap-table-pagination-total {
          position: absolute;
          top: 10px;
          right: -83%;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: #2D3748;
          .total-size {
            color: #718096;
          }
        }
      }
      .table-wrapper {
          &.dispatch-main {
            .table {
              .reset-expansion-style{
                &.no-children {
                  .inner-table-row {
                    .expand-cell {
                      svg {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }
          .table {
              border-collapse: separate;
              border-spacing:0 7px;
              .child-table {
                .table{
                  border-collapse: unset !important;
                  border-spacing:0 2px;
                  .header-class {
                    border: unset;
                    th {
                      border-top: unset !important;
                      padding-bottom: 13px;
                      &:first-child {
                        border-left: unset ;
                        border-top-left-radius: unset;
                        border-bottom-left-radius: unset;
                      }
                      &:last-child {
                        border-right: unset ;
                        border-top-right-radius: unset;
                        border-bottom-right-radius: unset;
                      }
                    }
                  }
                  tbody:before {
                    content:"";
                    display:none;
                    line-height:unset;
                    text-indent:-99999px;
                  }
                  .inner-table-row {
                    td { 
                      border: unset;
                      .font-12 {
                        font-size: 12px;
                      }
                    }
                  }
                }
              }
              .inner-table {
                .table{
                  background-color:unset !important;
                  .font-12 {
                    font-size: 12px;
                  }
                  .inner-table-row {
                    background-color: #fff !important;
                    height: 50px;
                    td {
                      border-top: unset;
                      vertical-align: middle;
                      font-weight: 400;
                      font-style: normal;
                      font-size: 14px;
                      border-bottom-width: 0;
                      max-width: 300px;
                    }
                  }
                }
              }
              .no-children:parent {
                  display: none;
              }
              .custom-table-row {
                  background-color: #fff !important;
                  height: 45px;
                  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
                  
                  &.dispatch-table {
                    height: 60px;
                  }
                  
                  td {
                      border-top: unset;
                      vertical-align: middle;
                      font-weight: 400;
                      font-style: normal;
                      font-size: 14px;
                      border-bottom-width: 0;
                      max-width: 300px;
                      padding-top: 0px;
                      padding-bottom: 0px;
                      .underline {
                        text-decoration: underline;
                      }
                      .font-12 {
                        font-size: 12px;
                      }
                  }
              }
              .reset-expansion-style  {
                background: #FAFDFF;
                border-bottom-width: 0;
                border-top-width: 0;
                padding-bottom: 0px;
                padding-top: 0px;
                .heading {
                  font-style: normal;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 200%;
                  color: #80808B;
                }
                .expand-data {
                  padding-bottom: 5px;
                  padding-top: 5px;
                  .label {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 200%;
                    color: #2F2E41;
                  }
                  .value {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 200%;
                    color: #2F2E41;
                  }
                }
              }

              
          }
          overflow-x: hidden;
          tbody:before {
            content:"@";
            display:block;
            line-height:5px;
            text-indent:-99999px;
          }
          .header-class {
              border: 1px solid #E2E8F0;
              margin-bottom: 10px;
              th{
                  border: unset;
                  border-bottom: 1px solid #E2E8F0 ;
                  border-top: 1px solid #E2E8F0 ;
                  font-weight: 500;
                  font-style: normal;
                  font-size: 12px;
                  text-transform: uppercase;
                  &:first-child {
                    border-left: 1px solid #E2E8F0 ;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                  }
                  &:last-child {
                    border-right: 1px solid #E2E8F0 ;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                  }
              }

          }
          
      }
  }
    .search {
      .user-select-column {
        padding-left: 30px;
        padding-right: 25px;
      }
      &.dispatch-search {
        .select-column{
          max-width: 290px;
        }
      }
      &.suites-search,
      &.users-search,
      &.promo-search {
        .select-column{
          max-width: 285px;
        }
      }
      .select-column{
        max-width: 230px;
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
      }
      .input-group {
          max-width: 232px;
            .input-group-btn{
              position: absolute;
              top: 5px;
              right: 0px;
              z-index: 9;
            }
        }
        .form-control{
            border-radius: 6px !important;
        }
    }

    .react-bootstrap-table {
      .header-class {
        .filter-label {
        display: none;
        }
      }

      .sortable{
            .caret {
                display: inline-block;
                width: 0;
                height: 0;
                margin-left: 15px;
                vertical-align: middle;
                border-top: 4px dashed;
                border-top: 4px solid \9;
                border-right: 4px solid transparent;
                border-left: 4px solid transparent;
            }
            .order{
                .dropup {
                    .caret{
                        display: none;
                    }
                }
                  
               .dropdown {
                    .caret {
                            border-top: 0;
                            border-bottom: 4px dashed;
                            border-bottom: 4px solid \9;
                            content: "";
                        }
                }
            }

            .react-bootstrap-table-sort-order{
                &.dropup {
                    .caret{
                        display: inline-block !important;
                        transform: rotate(180deg); 
                    }
                }

                .navbar-fixed-bottom .dropdown .caret {
                    border-top: 0;
                    border-bottom: 4px dashed;
                    border-bottom: 4px solid \9;
                    content: "";
                }
            }
      }
      .google-color {
        color: #38A169  !important;
        font-weight: 600  !important;
        font-size: 14px  !important;
      }

      .apple-color {
        color: #FF7F5C  !important;
        font-weight: 600  !important;
        font-size: 14px  !important;
      }
      .facebook-color {
        color: #153F90  !important;
        font-weight: 600  !important;
        font-size: 14px  !important;
      }
    
      .highlighted-text{
        font-style: normal !important;
        font-weight: 500  !important;
        font-size: 14px  !important;
        line-height: 20px  !important;
        color: #1E88E5  !important;
      }
      
      .plan_premium {
        background-color: #153F90;
        color: #FFF;
        padding-top: 2px;
        padding-bottom: 2px;
        padding-right: 5px;
        padding-left: 5px;
        font-family: Inter;
        font-style: normal;
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
        border-radius: 2px;
        width: fit-content;
        text-align: center;
      }
      
      .status,
      .ready-to-dispatch,
      .delivered,
      .payment-confirmed,
      .plan_basic,
      .ready_dispatch_status,
      .active_status  {
        background-color: #38A169;
        color: #FFF;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-right: 10px;
        padding-left: 10px;
        font-family: Inter;
        font-style: normal;
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
        border-radius: 2px;
        width: fit-content;
      }
      .payment-pending,
      .in-process,
      .consolidation-required,
      .return-to-shipper,
      .plan_business {
        background-color: #FF7F5C;
        color: #FFF;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-right: 10px;
        padding-left: 10px;
        font-family: Inter;
        font-style: normal;
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
        border-radius: 2px;
        width: fit-content;
      }
      .received,
      .consolidated,
      .open-conditions,
      .dispatched_status, 
      .dispatched-from-origin{
        background-color: #1E88E5;
        color: #FFF;
        padding-top: 2px;
        padding-bottom: 3px;
        padding-right: 6px;
        padding-left: 6px;
        font-family: Inter;
        font-style: normal;
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
        border-radius: 2px;
        width: fit-content;
      }
      .odd-sized-weight,
      .expired{
        background-color: #D22F27;
        color: #FFF;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-right: 10px;
        padding-left: 10px;
        font-family: Inter;
        font-style: normal;
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
        border-radius: 2px;
        width: fit-content;
      }
      .inactive_status {
        background-color: #8494B2;
        color: #FFF;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-right: 10px;
        padding-left: 10px;
        font-family: Inter;
        font-style: normal;
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
        border-radius: 2px;
        width: fit-content;
        text-align: center;
      }
      .percent_discount,
      .empty-status {
        background-color: #FBB231;
        color: #FFF;
        padding-top: 2px;
        padding-bottom: 3px;
        padding-right: 6px;
        padding-left: 6px;
        font-family: Inter;
        font-style: normal;
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
        border-radius: 2px;
        width: fit-content;
        text-align: center;
      }
      .flat_discount {
        background-color: #15397F;
        color: #FFF;
        padding-top: 2px;
        padding-bottom: 3px;
        padding-right: 6px;
        padding-left: 6px;
        font-family: Inter;
        font-style: normal;
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
        border-radius: 2px;
        width: fit-content;
        text-align: center;
      }
      .status {
        padding-top: 2px;
        padding-bottom: 3px;
        padding-right: 5px;
        padding-left: 5px;
      }
    }
  }
  .tabs-data {
    overflow-x: hidden;
    .nav-item {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #2D3748;
      .badge {
        background-color: #EAEBED;
        color: #2D3748;
        border-radius: 10px;
      }
      &.active {
        color: #2B6CB0;
        border: unset;
        border-bottom: 1px solid #1E88E5;
        .badge {
          background-color: #E8F3FC;
          color: #1E88E5;
        }
      }
      &:hover {
        color: #2B6CB0;
        border: unset;
      }
      &:focus {
        border-bottom: 1px solid #1E88E5;
      }
    }
    .tab-content {
      padding: 0px !important;
      background: #FBFAFA;
      .table-responsive {
        overflow-x: hidden;
      }
    }
  }
  &.login-container {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    position: fixed;
    background: #fff;
    .unauthorized {
      height: 90vh;
      flex-direction: column;
      text-align: center;
      .user {
        color: #1E88E5
      }
      .login-continue {
        color: #1A365D;
      }
    }
  }
}

.en {
  &.main-container {
    .table-container {
      .search {
        .search-width {
          max-width: 320px;
          padding-left: 0px !important;
        }
        &.dispatch-search {
          .select-column{
            margin-right: 30px !important;
          }
        }
        .select-column{
          margin-right: 23px;
          appearance: auto;
          &:focus {
            border: 1px solid #E2E8F0;
            box-shadow: unset;
          }
        }
      }
    }
    #suites-tab-tabpane-suites {
      .table-container {
        .search {
          .select-column{
            margin-right: 0px !important;
            max-width: 200px !important;
            margin: auto;
          }
        }
      }
    }
    #suites-tab-tabpane-basic {
      .table-container {
        .search {
          .select-column{
            margin-right: 0px !important;
            max-width: 200px !important;
            margin: auto;
          }
        }
      }
    }
  }
}

.ar {
  &.main-container {
    .table-container {
      .search {
        .search-width {
          max-width: 320px;
          padding-right: 0px !important;
        }
      }
    }
  }
}
@media screen and (max-width: 760px) {
.search {
  &.dispatch-search {
    .select-column{
      max-width: 280px;
    }
  }
  &.suites-search, 
  &.users-search,
  &.promo-search {
    .select-column{
      max-width: 270px;
    }
  }
}
}
@media screen and (min-width: 760px) and (max-width: 1024px){
  .react-bootstrap-table-pagination-total {
    right: -60% !important;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1724px){
  .react-bootstrap-table-pagination-total {
    right: -73% !important;
  }
}
@media screen and (max-width: 1024px) {
  .react-bootstrap-table-pagination-total {
    position: unset !important;
    display: flex;
    margin-top: 10px;
  }
  .table-wrapper {
    overflow-x: auto !important;
  }
}

.react-bootstrap-table-sort-order{
  .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
    
  .dropup, .dropdown {
    position: relative;
  }
    
  .dropup .caret, .navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid \9;
    content: "";
  }
}